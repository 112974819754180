<template>
    <main>
        <h1 class="headline blue--text text-center mt-4 mb-3">Puheluraportti</h1>
        <v-divider></v-divider>
        <v-row>
            <v-col>
                <v-select
                    v-model="selected_projects"
                    :items="projects_list"
                    :return-object="false"
                    item-value="id"
                    item-name="name"
                    label="Valitse projekti(t)"
                    multiple
                    clearable
                >
                    <template #item="{ item }">
                        {{ item.name }}
                    </template>
                    <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                          <span v-if="selected_projects.length > 1">+ {{ selected_projects.length -1 }} muuta</span>
                      </span>
                    </template>
                    <template v-slot:prepend-item>
                        <v-list-item
                            @click="toggleAll('selected_projects', 'projects_list')"
                        >
                            <v-checkbox
                                @click="toggleAll('selected_projects', 'projects_list')"
                                v-model="allSelected['selected_projects']"
                            ></v-checkbox>
                            Valitse kaikki
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-select>
            </v-col>
            <v-col>
                <v-select
                    v-model="selected_subprojects"
                    :items="subprojects_list"
                    :return-object="false"
                    item-value="id"
                    item-name="name"
                    label="Valitse aliprojekti(t)"
                    multiple
                    clearable
                >
                    <template #item="{ item }">
                        {{ item.name }}
                    </template>
                    <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                          <span v-if="selected_subprojects.length > 1">+ {{ selected_subprojects.length-1 }} muuta</span>
                      </span>
                    </template>
                    <template v-slot:prepend-item>
                        <v-list-item
                            @click="toggleAll('selected_subprojects', 'subprojects_list')"
                        >
                            <v-checkbox
                                @click="toggleAll('selected_subprojects', 'subprojects_list')"
                                v-model="allSelected['selected_subprojects']"
                            ></v-checkbox>
                            Valitse kaikki
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-select>
            </v-col>
            <v-col>
                <v-autocomplete
                    v-model="selected_number"
                    :search-input.sync="search_number"
                    :items="numbers_list"
                    :return-object="false"
                    item-value="id"
                    item-text="number"
                    label="Valitse numero"
                    clearable
                >
                    <template #item="{ item }">
                        {{ item.number }}
                    </template>
                    <template #selection="{ item }">
                        {{ item.number }}
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                  v-model="selected_agent"
                  :search-input.sync="agent_search"
                  :items="agents_list"
                  :return-object="false"
                  item-value="agent_id"
                  item-text="name"
                  label="Valitse agentti"
                  clearable
              >
                <template #item="{ item }">
                  {{ item.name }}
                </template>
                <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                      </span>
                </template>
              </v-autocomplete>
              <!--
                <v-select
                    v-model="selected_agent"
                    :items="agents_list"
                    :return-object="false"
                    item-value="agent_id"
                    item-name="name"
                    label="Valitse agentti"
                    clearable
                >
                    <template #item="{ item }">
                        {{ item.name }}
                    </template>
                    <template #selection="{ item, index }">
                      <span v-if="!index">
                          {{ item.name }}
                      </span>
                    </template>
                </v-select>
                -->
            </v-col>
            <v-col>
                <v-select
                    v-model="selected_direction"
                    :items="[
                        'INBOUND',
                        'OUTBOUND',
                        'CREDIO',
                        'HOSTESS',
                        'ALL'
                    ]"
                    :return-object="false"
                    label="Valitse suunta"
                >
                </v-select>
            </v-col>
            <v-col>
              <v-text-field v-model="customer_phone" placeholder="040123456" label="asiakkaan numero" class="mt-1" clearable/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-text-field v-model="startDate" placeholder="01.12.23" label="alku"/>
            </v-col>
            <v-col cols="2">
                <v-text-field v-model="endDate" placeholder="31.12.23" label="loppu"></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-btn
                    class="v-btn ma-3 green"
                    @click="getResource"
                    small
                    :disabled="resourceLoading"
                >
                    HAE
                </v-btn>
            </v-col>
        </v-row>

        <template v-if="resource">
          <v-card class="mb-4">
              <v-card-text>
                  <v-simple-table>
                      <thead>
                      <tr>
                          <th>
                              Palvelu
                          </th>
                          <th>
                              Kuvaus
                          </th>
                          <th>
                              Projekti
                          </th>
                          <th>
                              Aliprojekti
                          </th>
                          <th>
                              Numero
                          </th>
                          <!--
                          <th>
                              Ohjausnumero
                          </th>
                          -->
                          <th>
                              Soittaja
                          </th>
                          <th>
                              Maksu
                          </th>
                          <th>
                              Hinta
                          </th>
                          <th>
                              Tapahtumat
                          </th>
                          <!--
                          <th>
                              Aikaleima
                          </th>
                          -->
                          <th>
                              Vastaus
                          </th>
                          <th>
                              Lopetus
                          </th>
                          <th>
                              Kesto
                          </th>
                          <th>
                              Laskutuskesto
                          </th>
                          <th>
                              Suunta
                          </th>
                      </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(tr,key) in resource.calls" :key="'tr'+key">
                              <td>
                                  <template v-if="tr.service">
                                      {{ tr.service.name }}
                                  </template>
                              </td>
                              <td>
                                  <template v-if="tr.service">
                                      {{ tr.service.description }}
                                  </template>
                              </td>
                              <td>
                                  <template v-if="tr.service && tr.service.sub_project && tr.service.sub_project.project">
                                      {{ tr.service.sub_project.project.name}}
                                  </template>
                              </td>
                              <td>
                                  <template v-if="tr.service && tr.service.sub_project">
                                      {{ tr.service.sub_project.name}}
                                  </template>
                              </td>
                              <td>
                                  <template v-if="tr.number">
                                  {{ tr.number.number }}
                                  </template>
                              </td>
                              <!--
                              <td>
                                  {{ tr.number.number }}
                              </td>
                              -->
                              <td>
                                  <template v-if="tr.consumer">
                                  {{ tr.consumer.identification }}
                                  </template>
                              </td>
                              <td>
                                  {{ tr.payment_type.name }}
                              </td>
                              <td>
                                  {{ tr.price }}
                              </td>
                              <td>
                                  <v-icon @click="seeEvents(tr)">mdi-eye</v-icon>
                              </td>
                              <!--
                              <td>
                                  {{ tr.cdr.calldate }}
                              </td>
                              -->
                              <td>
                                  {{ tr.cdr.answerdate }}
                              </td>
                              <td>
                                  {{ tr.cdr.hangupdate }}
                              </td>
                              <td>
                                  {{ tr.cdr.duration_string }}
                              </td>
                              <td>
                                  {{ tr.cdr.billsec_string }}
                              </td>
                              <td>
                                  {{ tr.direction }}
                              </td>
                          </tr>
                      </tbody>
                  </v-simple-table>
              </v-card-text>
          </v-card>
          <v-card class="mb-16">
            <v-card-title>
              Yhteenveto:
            </v-card-title>
            <v-card-text>
              Yhteensä: {{ resource.amount }}
              <v-spacer></v-spacer>
              Yhteiskesto: {{ resource.total_duration }}
              <v-spacer></v-spacer>
              Laskutettu: {{ resource.charged_duration }}
              <v-spacer></v-spacer>
              Tuotto: {{ resource.turnover }}
            </v-card-text>
          </v-card>
        </template>
        <v-card v-else-if="resourceLoading">
            <v-card-text>
                Ladataan...
            </v-card-text>
        </v-card>

        <!-- events dialog -->
        <v-dialog
            v-model="dialog"
            max-width="1200"
        >
            <v-card>
                <v-card-text v-if="selected_event">
                    <template v-if="selected_event_call_details.length > 0">
                        Puhelun tunniste: {{ this.selected_event_call_details[0].call_unique_id }}<br/>
                    </template>
                    Soittaja: {{ this.selected_event.consumer.identification }} ({{ this.selected_event.consumer.consumer_operator }})<br/>
                    Palvelu: {{ this.selected_event.service.name }}<br/>
                    Numero: {{ this.selected_event.number.number }}<br/>
                    Puhelun kesto: {{ this.selected_event.cdr.duration_string }}<br/>
                    Laskutuskesto: {{ this.selected_event.cdr.billsec_string }}<br/>
                </v-card-text>

                <template v-if="selected_event_call_details.length > 0">
                    <v-card-title>
                        Tapahtumat
                    </v-card-title>

                    <v-simple-table>
                        <thead>
                            <tr>
                                <td>
                                    Puhelukomponentti
                                </td>
                                <td>
                                    Aika
                                </td>
                                <td>
                                    Tapahtuma
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in selected_event_call_details" :key="item.id">
                                <td>
                                    {{ item.component }}
                                </td>
                                <td>
                                    {{ item.added_time }}
                                </td>
                                <td>
                                    {{ item.info }}
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </template>
                <v-card-title v-else>
                    Ei tapahtumia
                </v-card-title>
            </v-card>
        </v-dialog>
    </main>
</template>

<script>

export default {
    name: "CallsReport",
    data: function () {
        return {
            startDate: null,
            endDate: null,
            agents_list: [],
            projects_list: [],
            subprojects_list: [],
            selected_subprojects: [],
            selected_projects: [],
            numbers_list: [],
            selected_number: null,
            agent_search: null,
            selected_agent: null,
            search_number: null,
            resourceLoading: false,
            resource: null,
            allSelected: {
                selected_projects: false,
                selected_medias: false,
            },
            selected_direction: "INBOUND",
            answered_only: false,
            selected_event: null,
            selected_event_call_details: {},
            customer_phone: null,
            dialog: false,

        }
    },
    methods: {

        toggleAll(to, from){
            if(this.allSelected[to]){
                this[to] = []
                this.allSelected[to] = false
            }else{
                this.allSelected[to] = true
                this[to] = this[from].map(item => item.id);
            }

        },

        duration(seconds) {

            if(seconds == 0) return '00:00'

            const hours = Math.floor(seconds / 1000 / 3600);
            const minutes = Math.floor((seconds / 1000 % 3600) / 60);
            const remainingSeconds =  Math.floor(seconds / 1000 % 60);

            if(hours > 0)
                return `${hours}:${minutes}:${remainingSeconds}`;
            else
                return `${minutes}:${remainingSeconds}`;
        },

        async getResource(){
            if(this.resourceLoading) return
            this.resourceLoading = true
            this.resource = null
            await this.axios.post('reports/calls', {
                from: this.startDate,
                to: this.endDate,
                projects: this.selected_projects,
                sub_projects: this.selected_subprojects,
                number_id: this.selected_number,
                agent_id: this.selected_agent,
                direction: this.selected_direction,
                answered_only: this.answered_only ? 1 : 0,
                skip: 0,
                take: 300,
                customer_phone: this.customer_phone
            }).then((resources) => {
                this.resource = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.resourceLoading = false
        },
        seeEvents(selected_event){
            this.selected_event = selected_event
            this.selected_event_call_details = {}
            this.axios.get('reports/call_details?cdr_id='+selected_event.cdr.id).then((r) => {
                this.selected_event_call_details = r.data
                this.dialog = true
            })
        }
    },
    mounted() {
        //this.startDate = this.$day().startOf('month').format("DD.MM.YY")
        //this.startDate = this.$day().subtract(1, 'day').format("DD.MM.YYYY")
        this.startDate = this.$day().format("DD.MM.YY")
        this.endDate = this.$day().format("DD.MM.YY")

        /*
        {
            "from": "10.09.23",
            "to": "20.09.23",
            "projects":[225],
            "sub_projects":[],
            "number_id": "",
            "agent_id": "341",
            "direction": "ALL",
            "answered_only":"0"
        }
         */

        //prefetch lists list
        this.axios.get('reports/projects_list').then((r) => {
            this.projects_list = r.data
        }) //get projects_list
        this.axios.post('reports/subprojects_list', {projects: []}).then((r) => {
            this.subprojects_list = r.data
        }) //get subprojects_list (projects limit results
        this.axios.get('numbers').then((r) => {
            this.numbers_list = r.data
        }) //get numbers_list (?keyword=05054)
        //prefetch lists
        this.axios.get('reports/agent_list').then((r) => {
            this.agents_list = r.data
        }) //get agents_list

    }
}
</script>

<style scoped>

</style>